import { useState, useRef, useEffect,  useContext } from 'react';
import * as d3 from 'd3';
import { SolvedContext, GuessSubmittedContext, api_url} from './App';

export function D3Background () {
  // Function that generates a wordmap background using D3 Library. We retrieve the words and
  // coordinates for the wordmap by making a backend call which retrieves the dictionary of words.
  // This function also plots guesses as the User inputs them using the GuessSubmittedContext and an
  // API call to mapword function, which returns the coordinate of the guess.

    const d3container = useRef(null);
    const {guess_Submitted, set_Guess_Submitted} = useContext(GuessSubmittedContext);
    const {solved, _} = useContext(SolvedContext);
    const [coordinates, setCoordinates] = useState({});
    const [wordCoordinate, setWordCoordinate] = useState([]);
    const [wiki_title_2d_list , setWiki_title_2d_list ] = useState([]);
    let first_visit = true;



    const fonts = ['Merriweather', 'cursive', 'fantasy', 'Roboto', 'sans-serif', 'monospace', 'Open Sans', 'Lato', 'Montserrat'];


    function hashCode(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
          const char = str.charCodeAt(i);
          hash = ((hash << 5) - hash) + char;
          hash |= 0;
      }
      return hash;
    }
 
    function getFontSizeForWord(word) {
        const baseSize = 15;
        const range = 20;
   
        const hash = hashCode(word);
        const random = Math.abs(hash % range);
        return baseSize + random;
    }
    const scaleX = (value) => {
        return ((value + 1) / 6) * d3container.current.clientWidth;
    };
  
    const scaleY = (value) => {
        return ((value + 1) / 6) * d3container.current.clientHeight;
    };


    const positionGuesses = () => {
      first_visit = false;
      const svg = d3.select(d3container.current)
                    .attr('width', window.innerWidth)
                    .attr('height', window.innerHeight);  
      if (!svg || Object.keys(wordCoordinate).length === 0) {
        return;
      }
  
      const guessData = Object.entries(wordCoordinate).map(([word, coordinates]) => {
        const [x, y] = coordinates;
        const scaledX = scaleX(x);
        const scaledY = scaleY(y);
        const color = 'grey';
        return {
          text: guess_Submitted,
          x: scaledX,
          y: scaledY,
          fontSize: getFontSizeForWord(word),
          opacity: 0.2,
          fill: color
        };
      });
      svg.selectAll('.guess')
        .data(guessData)
        .join('text')
        .attr('class', 'guess')
        .attr('x', d => d.x)
        .attr('y', d => d.y)
        .style('font-weight', 'bold')
        .text(d => d.text)
        .attr('font-size', d => `${d.fontSize}px`)
        .style('fill', d => d.fill)
        .attr('class', 'word non-selectable-text')
        .style('opacity', d => d.opacity);
       
 
 
        if (wiki_title_2d_list.length > 0) {
          const targetCoord = wiki_title_2d_list[0];
          const [targetX, targetY] = targetCoord;
          const scaledTargetX = scaleX(targetX);
          const scaledTargetY = scaleY(targetY);

          svg.selectAll('.target')
            .data([targetCoord])
            .join('text')
            .attr('class', 'target')
            .attr('x', scaledTargetY)
            .attr('y', scaledTargetX)
            .text('*')
            .attr('font-size', '30px')
            .style('fill', 'green');
     
            guessData.forEach(guess => {
              svg.append('line')
                .attr('x1', guess.x)
                .attr('y1', guess.y)
                .attr('x2', guess.x)
                .attr('y2', guess.y)
                .attr('opacity', '0.1')
                .attr('stroke', 'black')
                .attr('stroke-width', 4)
                .transition()
                .duration(1000)
                .attr('x2', scaledTargetX)
                .attr('y2', scaledTargetY)
                .transition()
                .duration(1000)
                .style('opacity', 0)
                .remove();
            });
        }
    };
 
 
 
 
      const positionWords = () => {
        first_visit = false;
        const svg = d3.select(d3container.current)
        .attr('width', window.innerWidth)
        .attr('height', window.innerHeight);    

        const data = Object.entries(coordinates).map(([word, [x, y]]) => {
          const scaledX = scaleX(x);
          const scaledY = scaleY(y);
     
          return {
            text: word,
            x: scaledX,
            y: scaledY,
            fontSize: getFontSizeForWord(word),
            opacity: 0.2,
            z: fonts[word.length]
          };
        });
     
        svg.selectAll('.word')
          .data(data)
          .join('text')
          .attr('class', 'word')
          .attr('x', d => d.x)
          .attr('y', d => d.y)
          .text(d => d.text)
          .style('font-weight', 'bold')
          .attr('font-size', d => `${d.fontSize}px`)
          .style('fill', 'black')
          .style('font-family', d => d.z)
          .attr('class', 'word non-selectable-text')
          .style('opacity', d => d.opacity);
      };
 
     
 
    let temp_cond = false;
    useEffect(() => {
      if (temp_cond === false){
      const fetchCoordinates = async () => {
          try {
              const response = await fetch(api_url + '/wordmap');
              if (!response.ok) {
                  throw new Error(`Problem: ${response.status}`);
              }
              const data = await response.json();
              setCoordinates(data);
          } catch (error) {
              console.error("API error: " + error);
          }
      };
 
 
      fetchCoordinates();
    }
  }, []);
 
 
 
 
  useEffect(() => {
    const fetchCoordinate = async () => {
        if (guess_Submitted) {
            try {
                const response = await fetch(api_url + '/mapword', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 'word': guess_Submitted })
                });
 
 
                if (!response.ok) {
                    throw new Error(`Problem: ${response.status}`);
                }
 
                if (response !== "Invalid"){
                  const data = await response.json();
                  setWordCoordinate(data[0]);
                  setWiki_title_2d_list(data[1]);
                  setCoordinates((prev) => ({...prev, [guess_Submitted]: data[0][0]}));  
                }

            } catch (error) {
                console.error("API error: " + error);
            }
        }
    };
 
 
    fetchCoordinate();
  }, [guess_Submitted]);
 
 

    useEffect(() => {

      positionWords();
      if(!first_visit) {
      positionGuesses();
      }

    }, [coordinates, wordCoordinate]);

    return (
      <svg ref={d3container} style={{ width: '100%', height: '100%'}} />
    );
  };
