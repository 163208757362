import { createContext, useState, React } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import './main.css'
import {io} from 'socket.io-client';
import Infinite from './Infinite';
import Daily from './Daily';
import HomePage from './Homepage';
import Multiplayer from './Multiplayer';

export const GuessContext = createContext();
export const SolvedContext = createContext();
export const HintsContext = createContext();
export const LoggedUserContext = createContext();
export const IsLoginButtonClickedContext = createContext();
export const GuessSubmittedContext = createContext();
export const LoadingContext = createContext();
export const CategoryContext = createContext();
export const api_url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000/api"; // deployment vs testing api urls
export const socketUrl = process.env.REACT_APP_SOCKET_URL || "http://localhost:4000"; // deployment vs testing socket url
export const socket = io(socketUrl);

const IsLoginButtonClickedContextProvider = ({children}) => {
  const [isLoginButtonClicked, setIsLoginButtonClicked] = useState(false); 
  const value = {isLoginButtonClicked, setIsLoginButtonClicked};

  return (
    <IsLoginButtonClickedContext.Provider value={ value }>
      { children }
    </IsLoginButtonClickedContext.Provider>
  );
};

const LoadingContextProvider = ({children}) => {  
  // can be set to true to show the loading screen
  const [loading, setLoading] = useState(false); 
  const value = {loading, setLoading};

  return (
    <LoadingContext.Provider value={ value }>
      { children }
    </LoadingContext.Provider>
  );
};

const HintsContextProvider = ({children}) => {
  // used to store the hints for the current game mode
  const [hints, set_hints] = useState({
    hidden: [],
    all: []
  });
  
  const value = {hints, set_hints};

  return (
    <HintsContext.Provider value={ value }>
      { children }
    </HintsContext.Provider>
  );
};

const GuessContextProvider = ({children}) => {
  // used to store the user guesses for the current game mode
  const [guesses, set_guesses] = useState({attempts: [], count: 0});
  const value = {guesses, set_guesses};

  return (
    <GuessContext.Provider value={ value }>
      {children}
    </GuessContext.Provider>
  );
};

const LoggedUserContextProvider = ({ children }) => {
  // used to store user info
  // tracks scores even when not logged in
  const [loggedUser, setLoggedUser] = useState({
    name: null, 
    completed_unlimited: 0, streak_unlimited: 0, longest_streak_unlimited: 0,
    completed_daily: 0, streak_daily: 0, longest_streak_daily: 0,
  });

  const value = {loggedUser, setLoggedUser}
  return (
      <LoggedUserContext.Provider value={value}>
          {children}
      </LoggedUserContext.Provider>
  );
};

const SolvedContextProvider = ({children}) => {
  // used to store whether the game has been completed in the current game mode
  const [solved, set_solved] = useState(false);
  const value = {solved, set_solved};

  return (
    <SolvedContext.Provider value={ value }>
      {children}
    </SolvedContext.Provider>
  );
};
const GuessSubmittedContextProvider = ({children}) => {
  const [guess_Submitted, set_Guess_Submitted] = useState('');
  const value = {guess_Submitted, set_Guess_Submitted};


  return (
    <GuessSubmittedContext.Provider value={ value }>
      {children}
    </GuessSubmittedContext.Provider>
  );
};

const CategoryContextProvider = ({ children }) => {
  const savedCategory = localStorage.getItem('selectedCategory');
  const [category, set_category] = useState(savedCategory || 'infinite');

  return (
    <CategoryContext.Provider value={[category, set_category]}>
      {children}
    </CategoryContext.Provider>
  );
};

const router = createBrowserRouter([
  // browser router is used to create the paths and associate them with a React component
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/infinite",
    element: <Infinite />
  },
  {
    path: "/daily",
    element: <Daily />,
  },
  {
    path: "/multiplayer",
    element: <Multiplayer />
  }
]);

export default function App() {

  return (
    <LoadingContextProvider>
    <IsLoginButtonClickedContextProvider>
    <HintsContextProvider>
    <SolvedContextProvider>
    <GuessContextProvider>
    <LoggedUserContextProvider>
    <GuessSubmittedContextProvider>
    <CategoryContextProvider>

      <RouterProvider router={router} />

    </CategoryContextProvider>
    </GuessSubmittedContextProvider>
    </LoggedUserContextProvider>
    </GuessContextProvider>  
    </SolvedContextProvider> 
    </HintsContextProvider>
    </IsLoginButtonClickedContextProvider>
    </LoadingContextProvider>
  );
}

