import { useContext, useEffect, useState, React, useRef } from 'react';
import { GuessContext, SolvedContext, HintsContext, api_url, LoggedUserContext, LoadingContext } from './App';
import { useSearchParams, useNavigate } from "react-router-dom";
import useScreenSize from './useScreenSize';
import { ReactComponent as Icon } from './assets/icon.svg';
import { CategoryContext } from './App';


export function Hints({page}){
  const [ searchParams, setSearchParams ] = useSearchParams();
  const navigate = useNavigate();
  
  const { loggedUser, setLoggedUser } = useContext(LoggedUserContext);
  const {loading, setLoading}  = useContext(LoadingContext);

  const {guesses, set_guesses} = useContext(GuessContext);
  const {solved, set_solved} = useContext(SolvedContext);

  const [num_hints, set_num_hints] = useState(guesses.count);
  const {hints, set_hints} = useContext(HintsContext);
  const screenSize = useScreenSize();
  const hints_div = useRef(null);
  const [cur_height, set_cur_height] = useState();
  const [category, set_category] = useContext(CategoryContext);

  const infinite_hints = async (user) => {
    setLoading(true);

    if (user){
      try {
        const response = await fetch(api_url + '/infinite_hints',  {
          method: "POST",
          headers: {
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify({'username': user, 'category': category})
        });
        if (!response.ok){
          throw new Error(`Problem: ${response.status}`);
        }
        const result = await response.json();
        if (result === "Invalid"){
          alert("4. Server error getting your infinite hints");
          setLoading(false);
          
        }
        else{
          set_hints({
            hidden: result[0],
            all: result[1],
          });
          
          setSearchParams({ 'a': result[2] });
          localStorage.setItem("hidden_hints_" + page, JSON.stringify(result[0]));
          localStorage.setItem("all_hints_" + page, JSON.stringify(result[1]));
          localStorage.setItem("a_" + page, JSON.stringify(result[2]));
          
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }

      }
      catch (error){
        console.log("API error: HINTS " + error);
      }
    }
    else{
      try {
        const response = await fetch(api_url + '/infinite_hints', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', 
          },
          body: JSON.stringify({
          'category': category
          }),
        });
        if (!response.ok){
          throw new Error(`Problem: ${response.status}`);
        }
        const result = await response.json();
        if (result === "Invalid"){
          alert("3. Server error getting your infinite hints");
          setLoading(false);
          
        }
        else{
          set_hints({
            hidden: result[0],
            all: result[1],
          });
          
          setSearchParams({ 'a': result[2] });
          console.log(result[0])
          localStorage.setItem("hidden_hints_" + page, JSON.stringify(result[0]));
          localStorage.setItem("all_hints_" + page, JSON.stringify(result[1]));
          localStorage.setItem("a_" + page, JSON.stringify(result[2]));
          
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }

      }
      catch (error){
        console.log("API error: HINTS " + error);
      }
    }
  };

  useEffect ( () => {
    if (hints_div.current){
      set_cur_height(hints_div.current.clientHeight);
    }
  }, [num_hints, screenSize])

  useEffect(() => {
    if (hints_div.current){
      set_cur_height(hints_div.current.clientHeight);
    }

    const all_hints = JSON.parse( localStorage.getItem("all_hints_" + page) );
    const hidden_hints = JSON.parse( localStorage.getItem("hidden_hints_" + page) );
    const a = JSON.parse( localStorage.getItem('a_'+page) );
    const cur_user_data = JSON.parse(localStorage.getItem("user"));        

    if (searchParams.get('a') && (!a || a != searchParams.get('a') ) && (page !== 'daily') && (page !== 'multiplayer')){
      setLoading(true);

      fetch(api_url+"/generate_hints", {
        method: "POST",
        headers: {
          'Content-Type' : 'application/json'
          },
        body: JSON.stringify(searchParams.get('a'))
      })
      .then(respone => respone.json())
      .then(result => {
        if (result == "Invalid"){
          alert("Invalid url");
          navigate('/'+page);
        }
        else {
          set_hints({
            hidden: result[0],
            all: result[1],
          });
          localStorage.setItem("hidden_hints_" + page, JSON.stringify(result[0]));
          localStorage.setItem("all_hints_" + page, JSON.stringify(result[1]));
          localStorage.setItem("a_" + page, JSON.stringify(result[2]));
          setSearchParams({'a': result[2]});
          setLoading(false);

        }
        
      });
    }
    else if ( page === "daily"){
      setLoading(true);    
      
      fetch(api_url + "/daily_hints")
      .then(response => response.json())
      .then(result => {
        if (result === "Invalid"){
          alert("1. Server error getting your daily hints.");
          setLoading(false);    
          
        }
        else if (all_hints && a !== null && result[2].slice(3,-3) === a.slice(3, -3)){
          // already have the hints and the searchParam matches the stored searchParam
          set_hints({
            hidden: hidden_hints,
            all: all_hints}
          );
          setLoading(false);    
    
          setSearchParams({'a': a});    
        }
        else {
          // otherwise reset the local storage and fetch the hints
          localStorage.setItem("a_daily", JSON.stringify(null));
          setSearchParams({'a': null});    
          localStorage.setItem("last_guesses_daily", JSON.stringify({attempts: [], count: 0}));
          set_guesses({attempts: [], count: 0})
          localStorage.setItem("solved_daily", JSON.stringify(false));
          set_solved(false);

          setLoading(true);    
          if ( cur_user_data.name ){
            // logged in and no stored hints
    
            fetch(api_url + "/daily_hints", {
              method: 'POST',
              headers: {
                'Content-Type' : 'application/json'
              },
              body: JSON.stringify(cur_user_data.name)
            })
            .then(response => response.json())
            .then( result => {
              
              if (result === "Invalid"){
                alert("2. Server error getting your daily hints");
                setLoading(false);
                
              }
              else{
                if (result.length == 4){
                  set_solved(true);
                  localStorage.setItem('solved_daily', JSON.stringify(true));
                  set_guesses({attempts: [], count: 6});
                  localStorage.setItem("last_guesses_daily", JSON.stringify({attempts: [], count: 6}));
                }
                
                set_hints({
                  hidden: result[0],
                  all: result[1],
                });
                
                setSearchParams({ 'a': result[2] });
      
                localStorage.setItem("hidden_hints_" + page, JSON.stringify(result[0]));
                localStorage.setItem("all_hints_" + page, JSON.stringify(result[1]));
                localStorage.setItem("a_"+page, JSON.stringify(result[2]));
                
                setTimeout(() => {
                  setLoading(false); 
                }, 1000);
              }
            });
          }
          else {
            // not logged in and no stored hints
            set_hints({
              hidden: result[0],
              all: result[1],
            });
            
            setSearchParams({ 'a': result[2] });
  
            localStorage.setItem("hidden_hints_" + page, JSON.stringify(result[0]));
            localStorage.setItem("all_hints_" + page, JSON.stringify(result[1]));
            localStorage.setItem("a_" + page, JSON.stringify(result[2]));
            
            setTimeout(() => {
              setLoading(false); 
            }, 1000);
          }
        }
      });

    }
    else if ( all_hints && page !== 'multiplayer'){
      // already in local storage. Update states. Both daily and infinite
      set_hints({
        hidden: hidden_hints,
        all: all_hints}
      );

      setSearchParams({'a': a});
    }
    else if (page === "infinite") {
      infinite_hints(cur_user_data.name);
    }
   
  }, []);
  
  useEffect(() => {
    set_num_hints(guesses.count);
  }, [guesses.count]);
     
  const handle_press = () => {
    if (page === "infinite"){
      set_solved(false);
      localStorage.setItem("solved_" + page, JSON.stringify(false));

      localStorage.setItem("last_guesses_" + page, JSON.stringify({
        attempts: [],
        count: 0
      }));

      set_guesses({
        attempts: [],
        count: 0
      });

      infinite_hints( loggedUser.name );
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center py-2" style={{width: '65%'}}>
        
      <div className={`d-flex shadow-lg flex-column justify-content-center p-3 ${loading ? "align-items-center" : "align-items-start" }`} style={{height: 'fit-content', width: '100%', background: 'white'}}  ref={hints_div}>
        { 

          loading ? 
            <Icon width = "40px" height = "40px" className = "load" />            
          :
            solved || guesses.count >= 6 ?
              hints.all.map((hint, ind) => (
                <div className= "p-2" key={ind + 300} style={{fontSize: `${screenSize.width > 680 ? "17px": "15px" }`}}>
                    { hint }
                </div>)) 
            :
              hints.hidden.slice(0,guesses.count + 1).map((hint, ind) => (
                <div className= "p-2" key={ind + 300} style={{fontSize: `${screenSize.width > 680 ? "17px": "15px" }`}}>
                    { hint }
                </div>))  
        }
      </div>
      
      <button 
        className='btn p-2 bg-off-white bg-gradient' 
        type='button'
        style={{width: "fit-content", height: `${cur_height}px`}}
        onClick={handle_press}
        hidden = {!solved && (guesses.count < 6) || page === "daily" || loading || page === 'multiplayer'}>
        <i className="bi bi-arrow-bar-right"></i>
      </button>
    </div>
  );
}