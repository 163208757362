import { useState } from "react";
import useScreenSize from "./useScreenSize";
import { api_url } from "./App";
import { useNavigate } from 'react-router-dom';

export function CustomWikiPopup(){
    /*
    Component for generating custom guessthewiki game from a wikipedia page title or link to wiki.
    Is displayed in a Modal React Bootstrap element.
    */
    const navigate = useNavigate();
    const [ input_val, set_input_value] = useState("");
    const [invalid_input, set_invalid_input] = useState(false);
    const [ input_type, set_input_type ] = useState('word'); // what format the input is

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch(api_url + "/verify_wiki", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                type: input_type,
                content: input_val
            })
        })
        .then(response => response.json())
        .then(result => {
            if (result === "False"){ // the wikipedia is not valid for guessthewiki
                set_invalid_input(true);

                setTimeout(() => {
                    set_invalid_input(false);
                }, 500);
            } 
            else if (result === "Invalid"){
                alert("Server error verifying wiki. Please try again.");
            }
            else {
                localStorage.setItem('solved_infinite', JSON.stringify(false));
                localStorage.setItem("last_guesses_infinite", JSON.stringify({attempts: [], count: 0}));
                localStorage.setItem("hidden_hints_infinite", JSON.stringify(null));
                localStorage.setItem("all_hints_infinite" , JSON.stringify(null));
                localStorage.setItem("a_infinite", JSON.stringify(null));

                navigate('/infinite/?a=' + result); // game is in the infinite game mode if valid
                window.location.reload(true);

            }
        });
    };

    return (
  
        <form className='d-flex flex-column justify-content-center align-items-center' >
            
            <div className="text-center fs-3">
                Make Your Own Game
            </div>
            <br/>

            <div className="d-flex" style={{gap: "1px"}} >
                <button className="btn p-l border border-1" style={{ backgroundColor: `${input_type === 'word' ? "white": "rgb(240,240,240)"}`}} type="button" onClick={ () => set_input_type('word')}> Title </button>
                <button className="btn p-l border border-1" style={{ backgroundColor: `${input_type === 'link' ? "white": " rgb(240,240,240)"}`}} type="button" onClick={ () => set_input_type('link')}> Link </button>

            </div>
            <div className='p-2'>
                {
                    input_type == "link" ?

                    <input type ="text"
                    value={input_val}
                    onChange = {(e) => set_input_value(e.target.value)}
                    placeholder='WikiPedia Link'
                    className= {`text-center ${invalid_input ? "shake" : ""}`} />

                    :
                    <input type ="text"
                    value={input_val}
                    onChange = {(e) => set_input_value(e.target.value)}
                    placeholder='WikiPedia Title'
                    className= {`text-center ${invalid_input ? "shake" : ""}`} />

                }
                
            </div>
            <br />
            
            <div className='container w-75 d-flex justify-content-center p-2'>
                <button 
                    type="submit" 
                    className='btn bg-off-white' 
                    onClick={handleSubmit} 
                >
                    Generate Game
                </button>
            </div>
            
        </form>
    
    );        
}