export default function Instruction(){
    // Component to display different game modes and their functionality
    return (
        <div>
            <h4>Rules</h4>
            <p>
                Make a guess based on the hints. After you make a guess, you will receive a score on how correct you are.
                The hints get increasingly more descriptive with each guess. You have six attempts to guess correctly. 
                Once you complete the round, you can click on the button to Guess a New Wiki. Register to keep your streak! Have fun GuesingTheWiki!
            </p>
            <br/>

            <h4>Daily</h4>
            <p>In daily mode, everyone will have the same Wiki. Have fun! Compete with your friends and see who GuessesTheWiki first!</p>
            <br/>

            <h4>Infinite</h4>
            <p>
                In infinite mode, each game is a randomly selected Wikipedia for you to enjoy! Play this for as long as you like! There are
                also categories like CompSci, History, Physics, and more you can choose to guess from! 
             </p>

            <h4>Multiplayer</h4>
            <p>
                In the Multiplayer mode, you can create rooms to play games with your friends online. 
                Set the number of rounds to play and share the room name with your friends. Once everyone is ready, you all have 60 seconds to 
                guess the wiki for each round and your best guess' score is updated tot he leaderboard. 
            </p>
        </div>

    );
}