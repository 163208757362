import { useState, useEffect } from 'react';


const useScreenSize = () => {
  /*
  Custom hook that returns the current window sizes. 
  Website has two modes:
    - Small screen, width < 680 
    - large screen, width >= 680
  The wordmap is not shown in the small screen mode and layout is optimized for small screen.
  */
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenSize;
};

export default useScreenSize;