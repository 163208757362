import React, { useState, useContext} from 'react';
import { LoggedUserContext, api_url } from './App';

export function LoginTemplates({onClose, onRegister}){
    const [username, setusername] = useState('');
    const [email, setEmail] = useState('');
    const [resetPasswordUsername, setresetPasswordUsername] = useState('');
    const [resetPasswordEmail, setresetPasswordEmail] = useState('');
    const [password, setpassword] = useState('');
    const [incorrect, set_incorrect] = useState(false);
    const [islogin, setIsLogin] = useState(true);
    const { loggedUser, setLoggedUser } = useContext(LoggedUserContext);
    const [forgottenPassword, setforgottenPassword] = useState(false);
    const [codeSent, setcodeSent] = useState('');
    const [codeConfirm, setCodeConfirm] = useState(false);
    const [codeInput, setcodeInput] = useState('');
    const [resetPassword, setresetPassword] = useState('');
    const [showError, setshowError] = useState('');

    function reset_page(){
        localStorage.setItem("hidden_hints_daily", JSON.stringify(null));
        localStorage.setItem("all_hints_daily" , JSON.stringify(null));
        localStorage.setItem("hidden_hints_infinite", JSON.stringify(null));
        localStorage.setItem("all_hints_infinite" , JSON.stringify(null));
        localStorage.setItem('solved_daily', JSON.stringify(false));
        localStorage.setItem("last_guesses_daily", JSON.stringify({attempts: [], count: 0}));
        localStorage.setItem('solved_infinite', JSON.stringify(false));
        localStorage.setItem("last_guesses_infinite", JSON.stringify({attempts: [], count: 0}));
    }

    const handleForgottenPassword = () => {
        setforgottenPassword(!forgottenPassword);
    }
    const logOut = () => {
        const user = {
            name: null,
            completed_unlimited: 0,
            streak_unlimited: 0,
            longest_streak_unlimited: 0,
            completed_daily: 0,
            streak_daily: 0,
            longest_streak_daily: 0
        }
        setLoggedUser(user);
        localStorage.setItem('user', JSON.stringify(user));
        reset_page();
        onClose();   
        window.location.reload(true);
    }

    const handleSubmitLogin = (event) => {
        event.preventDefault();
        
        if (username && password){
            fetch(api_url + '/login', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({username: username, password: password}),
                })
            .then(result => {
                
                return result.json()
    
            })
            .then(data => {
                if (data === "False") {
                    set_incorrect(true);
    
                    setInterval(() => {
                        set_incorrect(false);
                        
                    }, 600);
                }
                else if(data !== "Invalid") {
                    const user_data = {
                        name: data[0],
                        completed_unlimited: data[1],
                        streak_unlimited: data[2],
                        longest_streak_unlimited: data[3],
                        completed_daily: data[4],
                        streak_daily: data[5],
                        longest_streak_daily: data[6]
                    }
                    setLoggedUser(user_data);
                    localStorage.setItem("user", JSON.stringify(user_data));
                    reset_page();
                    onRegister();
                    onClose();
                    window.location.reload(true);
                }else {
                    alert('Server error while logging in. Please try again.')
                    window.location.reload(true);
                }
            });    
        }
    }

    const handlePasswordReset = (event) => {
        event.preventDefault();
        console.log(resetPasswordEmail);
        console.log(resetPasswordUsername);

        if (resetPasswordEmail && resetPasswordUsername){
            console.log("enter");
            fetch(api_url + '/forgot', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({username: resetPasswordUsername, email: resetPasswordEmail}),
                })
            .then(result => {
                return result.json()
                })
            .then(data => {
                if(data == 'Invalid') {
                    setshowError(true);
                }
                else {
                    setforgottenPassword(!forgottenPassword);
                    setCodeConfirm(!codeConfirm);
                    setshowError(false);
                }
            })
        }
    }

    const handlecodeInput = (event) => {
        event.preventDefault();


        if (codeInput){
            console.log("enter");
            fetch(api_url + '/reset/', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({token: codeInput, password: resetPassword}),
                })
            .then(result => {
                return result.json()
                })
            .then(data => {
                if(data == 'Invalid') {
                    console.log('Reset error')
                }
                else {
                    console.log('Correct Code Inputted')
                    setCodeConfirm(!codeConfirm);
                }
            })
        }
    }

    const handleSubmitRegister = (event) => {
        event.preventDefault();
        if (email && username && password){
            const user_data = JSON.parse(localStorage.getItem('user'))

            const post_data = {
                username: username, email: email, password: password,
                num_completed_unlimited: user_data.completed_unlimited,
                cur_streak_unlimited: user_data.streak_unlimited,
                longest_streak_unlimited: user_data.longest_streak_unlimited,
                num_completed_daily: user_data.completed_daily,
                cur_streak_daily: user_data.streak_daily,
                longest_streak_daily: user_data.longest_streak_daily
            }
            fetch(api_url + '/register_user', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(post_data),
                })
            .then(result => {
                return result.json()
            })
            .then(data => {
                if (data === "False") {
                    set_incorrect(true);
    
                    setInterval(() => {
                        set_incorrect(false);
                        
                    }, 600);
                    alert('Username exists')
                }
                else if (data !== "Invalid Error!!!!"){
                    setLoggedUser({...user_data, name: data});
                    localStorage.setItem("user", JSON.stringify({...user_data, name: data}));
                    onRegister();
                    onClose();
                    // reset_page();
                    window.location.reload(true);
                }else {
                    alert("Server error while registering. Please try again");
                    window.location.reload(true);
                }
            }); 
        
        }

               
    }
      
    const handleChange = () => {
        setIsLogin(!islogin);
        setshowError(false);
    }

    const handlePasswordChange = () => {
        setforgottenPassword(false);
        setCodeConfirm(false);
        setIsLogin(true);
        setshowError(false);
    }

    const login_in_template = (
        
        <form onSubmit= {handleSubmitLogin} className='p-2 px-4 '>

            <h2 className='p-2 pt-2 text-center'>Login</h2>
            
            <br/>

            <div className= {`p-2 ${incorrect ? "shake": ''}`} >
                <input type ="text"
                    value={username}
                    onChange = {(e) => setusername(e.target.value)}
                    placeholder='Username'/>
            </div>

            <div className= {`p-2 ${incorrect ? "shake": ''}`}>
                <input 
                    type = "password" 
                    value={password} 
                    onChange={(e) => setpassword(e.target.value)} 
                    placeholder="Password" />

            </div>
            <a href="#"
                onClick={(e) => {
                    e.preventDefault();
                    handleForgottenPassword();
                }}
                style={{marginLeft: '9px', fontSize: '15px'}}
                className='d-flex justify-content-end'>
                Forgot Password?
                </a>
            <div className='container w-75 d-flex justify-content-center p-2'>

            <button 
                    type="submit" 
                    className='btn bg-off-white' 
                    onClick={handleSubmitLogin}
                >
                    Log In
                </button>

            </div>
            
            <br/>

            <div className='container w-75 d-flex justify-content-center p-2 '>
                <button
                    type ="button"
                    aria-label="register"
                    className='btn w-75 bg-off-white'
                    onClick={handleChange}
                >
                    Register
                </button>
            </div>
        </form>

    );

    const register_template = (
        <form onSubmit= {handleSubmitRegister} className='p-2 px-4 d-flex flex-column align-items-center'>


            <h2 className='p-2 pt-2 text-center'>Register</h2>

            <br/>

            <div className= {`p-2 ${incorrect ? "shake": ''}`}>
                <input type ="text"
                    style={{border: '2px solid',borderColor: `${ username ? "rgb(0, 255, 0)" : "red"}`}}
                    value={username}
                    onChange = {(e) => setusername(e.target.value)}
                    placeholder='Username'/>
            </div>

            <div className='p-2'>
                <input 
                    style={{border: '2px solid',borderColor: `${ /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/.test(email) ? "rgb(0, 255, 0)" : "red"}`}}
                    id='email_register'
                    type ="text"
                    value={email}
                    onChange = {(e) => setEmail(e.target.value)}
                    placeholder = 'Email'/>
            </div>
            

            <div className='d-flex flex-column align-items-start p-2'>
                <input 
                    style={{border: '2px solid',borderColor: `${  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(password) ? "rgb(0, 255, 0)" : "red"}`}}
                    type = "password" 
                    value={password} 
                    id='password_register'
                    onChange={(e) => setpassword(e.target.value)} 
                    placeholder="Password" />

                <ul >
                    <li style={{fontSize: '13px'}}>At least 8 characters long,</li>
                    <li style={{fontSize: '13px'}}>At least 1 number,</li>
                    <li style={{fontSize: '13px'}}>At least 1 uppercase letter,</li>
                    <li style={{fontSize: '13px'}}>At least 1 lowercase letter.</li>

                </ul>
            </div>
            

            <div className='container w-75 d-flex justify-content-center p-2'>
                <button 
                    type="submit" 
                    className='btn bg-off-white' 
                    onClick={handleSubmitRegister}
                    disabled = {!(/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/.test(email)) || !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(password) || !username}
                >
                    Create Account
                </button>

            </div>
            <br/>
            
            <div className='container w-75 d-flex justify-content-center p-2'>

                <button
                    type ="button"
                    aria-label="login"
                    className='btn bg-off-white' 
                    onClick={handleChange}>
                        Login
                </button>
            </div>

        </form>
    );

    const forgotten_password_template = (
        <form onSubmit= {handlePasswordReset} className='p-2 px-4 d-flex flex-column justify-content-center align-items-center' style={{width: '300px'}}>
            <h2 className='p-2 pt-2 text-center'>Forgot Password? </h2>

            <br/>
            <div className= {`p-2 ${incorrect ? "shake": ''}`}>
                <input type ="text"
                    value={resetPasswordUsername}
                    onChange = {(e) => setresetPasswordUsername(e.target.value)}
                    placeholder='Username'/>
            </div>

            <div className='p-2'>
                <input 
                    type ="text"
                    value={resetPasswordEmail}
                    onChange = {(e) => setresetPasswordEmail(e.target.value)}
                    placeholder = 'email'/>
            </div>
            {
                showError && <div><p style={{color: 'red'}}> Incorrect username or email</p></div>
            }
            <div className='container w-75 d-flex justify-content-center p-2'>
                <button 
                    type="submit" 
                    className='btn bg-off-white' 
                    onClick={handlePasswordReset}
                >
                    Send Code
                </button>

            </div>
            <br/>
            
            <div className='container w-75 d-flex justify-content-center p-2'>
                <button
                    type ="button"
                    aria-label="login"
                    className='btn bg-off-white' 
                    onClick={handlePasswordChange}>
                        Login
                </button>
            </div>

        </form>
    );

    const input_code_template = (
        <form onSubmit= {handlecodeInput} className='p-2 px-4'>


            <h2 className='p-2 pt-2 text-center'>Input</h2>
            <h2 className='p-2 pt-2 text-center'>Password?</h2>

            <br/>

            <div className= {`p-2 ${incorrect ? "shake": ''}`}>
                <input type ="text"
                    value={codeInput}
                    onChange = {(e) => setcodeInput(e.target.value)}
                    placeholder='Code'/>
            </div>

            <div className= {`p-2 ${incorrect ? "shake": ''}`}>
                <input type ="text"
                    value={resetPassword}
                    onChange = {(e) => setresetPassword(e.target.value)}
                    placeholder='New password'/>
            </div>
            {
                showError && <div><p style={{color: 'red'}}> Incorrect code</p></div>
            }
            <div className='container w-75 d-flex justify-content-center p-2'>
                <button 
                    type="submit" 
                    className='btn bg-off-white' 
                    onClick={handlecodeInput}
                >
                    Submit
                </button>

            </div>
            <br/>
            
            <div className='container w-75 d-flex justify-content-center p-2'>

                <button
                    type ="button"
                    aria-label="login"
                    className='btn bg-off-white' 
                    onClick={handleChange}>
                        Register
                </button>
            </div>

        </form>
    );

    const profile_template = (
        <div className='p-2 px-5'>

            <div>
                <h2> {loggedUser.name} </h2>
            </div>
            <br/>

            <table className='table table-striped table-hover'>
                <thead>
                    <tr>
                        <th scope='col' className='text-center' width='130px'> Stats </th>
                        <th scope='col' className='text-center' width='110px'> <i className='bi bi-calendar'></i> &nbsp;Daily </th>
                        <th scope='col' className='text-center' width='110px'> <i className='bi bi-infinity'></i> &nbsp;Infinite </th>
                    </tr>
                    
                </thead>
                <tbody>
                    <tr>
                        <th scope='row'> Completed </th>
                        <td className='text-center'> { loggedUser.completed_daily } </td>
                        <td className='text-center'> { loggedUser.completed_unlimited } </td>
                    </tr>
                    
                    <tr>
                        <th scope='row'> Current Streak </th>
                        <td className='text-center'> { loggedUser.streak_daily } </td>
                        <td className='text-center'> { loggedUser.streak_unlimited } </td>
                    </tr>

                    <tr>
                        <th scope='row'> Longest Streak </th>
                        <td className='text-center'> { loggedUser.longest_streak_daily } </td>
                        <td className='text-center'> { loggedUser.longest_streak_unlimited } </td>
                    </tr>

                </tbody>
            </table>
            <br />

            <div className='container w-75 d-flex justify-content-center p-2'>
                <button 
                    type="button"
                    className='btn bg-off-white' 
                    onClick={logOut}
                >
                    Log Out
                </button>
            </div>

        </div>

        );

    
    if (loggedUser.name != null) {
        return profile_template
    }
    else if (forgottenPassword){
        return forgotten_password_template
    }
    else if (codeConfirm){
        return input_code_template
    }
    else if (!islogin){
        return register_template
    }
    else if (islogin){
        return login_in_template
    }

}
