import { useState, useContext, useEffect} from 'react';
import React from 'react';
import { Modal } from "react-bootstrap";
import { LoggedUserContext, IsLoginButtonClickedContext, api_url } from './App';
import { ReactComponent as FlameIcon } from './assets/flame.svg';
import useScreenSize from "./useScreenSize";
import { LoginTemplates } from "./LoginTemplates";

export function Login({page}){
    /*
        Login page component. 
        - If not logged in, displays templates to log in or register
        - If logged in, displays latest user scores for the daily and inifite game modes.
    */
    const { isLoginButtonClicked, setIsLoginButtonClicked } = useContext(IsLoginButtonClickedContext);
    const [ registered, setRegistered ] = useState(false);
    const { loggedUser, setLoggedUser } = useContext(LoggedUserContext);
    const screenSize = useScreenSize();

    useEffect( () => {
        const cur_user_data = JSON.parse(localStorage.getItem("user"));         
        if (cur_user_data){// if there is cached user data, update the loggedUser context 
            setLoggedUser(cur_user_data);
        }
        else { // create the default user data and update the cache to match
            const user = {
                name: null,
                completed_unlimited: 0,
                streak_unlimited: 0,
                longest_streak_unlimited: 0,
                completed_daily: 0,
                streak_daily: 0,
                longest_streak_daily: 0
            };
            setLoggedUser(user);
            localStorage.setItem("user", JSON.stringify(user));
        }
    }, []);

    const handleClick = () => {
        /*
            Event handler for when the login/user button is pressed.
            If logged in:
                - fetches user scores and displays it
            else
                - shows login/register page
        */
        const cur_user_data = JSON.parse(localStorage.getItem("user"));        
        if (cur_user_data.name != null){
            
            console.log('Updating info.')
            fetch(api_url + '/scores', { // use the scores flask endpoint to get latest score from the database
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cur_user_data.name),
                })
            .then(result => result.json())
            .then(data => {
                if (data !== "Invalid") {
                    const user_data = {
                        name: cur_user_data.name,
                        completed_unlimited: data[0],
                        streak_unlimited: data[1],
                        longest_streak_unlimited: data[2],
                        completed_daily: data[3],
                        streak_daily: data[4],
                        longest_streak_daily: data[5]
                    };
                    setLoggedUser(user_data);
                    localStorage.setItem("user", JSON.stringify(user_data));
                }
                else{
                    alert('Server error fetching your profile information');
                }
            });
        }
        setIsLoginButtonClicked(!isLoginButtonClicked); // shows the Modal component
    }

    const handleRegistered = () => {
        setRegistered(!registered);
    }; 

    return ( 
        <div className='d-flex justify-content-center align-items-center px-3'>
            
            {page !== "multiplayer" ? 
                <div className="d-flex justify-content-center align-items-center px-3">                    
                    <FlameIcon 
                        width=  {`${ screenSize.width > 680 ? "30px": "20px" }`} 
                        height= {`${ screenSize.width > 680 ? "30px": "20px" }`}  
                        fill = {`${ page == "infinite" ? loggedUser.streak_unlimited > 0 ? "red": "" : loggedUser.streak_daily > 0 ? "red": ""}`} 
                        />
                    
                    <div  className= {`d-flex justify-content-center align-items-center m-1 ${ screenSize.width > 680 ? "fs-4": "fs-3" }`}> 
                        {`${page == "infinite" ? loggedUser.streak_unlimited : loggedUser.streak_daily}`}
                    </div>
                </div>
            :
                <></>
            }

            <div className="d-flex justify-content-center align-items-center">
                <button 
                    className='btn bg-off-white bg-gradient d-flex justify-content-center align-items-center'
                    style={{width: `${screenSize.width > 680 ? "110px": "30px" }`}}
                    onClick={handleClick}
                >
                    {
                        screenSize.width > 680 ?
                            loggedUser.name != null ? 
                                <> <i className="bi bi-person"></i> &nbsp; {loggedUser.name.slice(0, 6)} </>
                            : 
                                <> <i className="bi bi-box-arrow-in-right"></i> &nbsp; Log In </>
                        : loggedUser.name != null ? <i className="bi bi-person"></i> : <i className="bi bi-box-arrow-in-right pr"></i>
                    }
                </button>
            </div>

        <Modal show = {isLoginButtonClicked} onHide={handleClick} centered className='position-absolute top-50 start-50  translate-middle' style={{width: "fit-content"}}>
            <Modal.Header closeButton>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-center' style={{overflow: 'auto'}}>
                <LoginTemplates onClose = {handleClick} onRegister={handleRegistered}/>
            </Modal.Body>
        </Modal>
        </div>
    );
}
