import './main.css'
import { useContext, useState, useEffect } from 'react';
import {ReactComponent as Biology} from './assets/biology.svg';
import {ReactComponent as Physics} from './assets/physics.svg';
import {ReactComponent as Computer} from './assets/computer.svg';

import {ReactComponent as Music} from './assets/music.svg';
import {ReactComponent as History} from './assets/history.svg';
import {ReactComponent as Movies} from './assets/movies.svg';

import {ReactComponent as Countries} from './assets/earth.svg';
import {ReactComponent as Space} from './assets/shuttle.svg';
import {ReactComponent as Cultures} from './assets/diversity.svg';
import { useNavigate } from 'react-router-dom';
import { CategoryContext } from './App';
import { useSearchParams } from 'react-router-dom';
import { HintsContext } from './App';

export default function GameModes( {closeModal}) {
    /* 
    Component for the category selection. Is displayed in a Modal component
    */
    const navigate = useNavigate();
    const [category, set_category] = useContext(CategoryContext);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const {hints, set_hints} = useContext(HintsContext);

    const nav = async (subject) => {
        localStorage.setItem("hidden_hints_daily", JSON.stringify(null));
        localStorage.setItem("all_hints_daily" , JSON.stringify(null));
        localStorage.setItem("hidden_hints_infinite", JSON.stringify(null));
        localStorage.setItem("all_hints_infinite" , JSON.stringify(null));
        localStorage.setItem('solved_daily', JSON.stringify(false));
        localStorage.setItem("last_guesses_daily", JSON.stringify({attempts: [], count: 0}));
        localStorage.setItem('solved_infinite', JSON.stringify(false));
        localStorage.setItem("last_guesses_infinite", JSON.stringify({attempts: [], count: 0}));
        localStorage.setItem('selectedCategory', subject);
        await set_hints({
            hidden: [],
            all: []
          });

        await set_category(subject);
        console.log(category);
    
        setSearchParams({ 'a': '' });
        closeModal();
        navigate('/infinite');
        window.location.reload();
    };

    // useEffect(() => {
    //     console.log(category);
    // }, [category]);

    return (        
        <div className='gamemodes-container'>
            <table className='gamemodes'>
            <tbody>
            <tr>
                <td><button onClick={() => nav('Infinite')}> Infinite <Music style = {{width: '60px', height: '60px', padding: '5px'}}/></button></td>
                <td><button onClick={() => nav('compsci')}>Compsci <Computer style = {{width: '60px', height: '60px', padding: '5px'}}/></button></td>
                <td><button onClick={() => nav('Physics')}>Physics <Physics style = {{width: '60px', height: '60px', padding: '5px'}}/></button></td>
            </tr>
            <tr>
                <td><button onClick={() => nav('History')}> History <History style = {{width: '60px', height: '60px', padding: '5px'}}/></button></td>
                <td><button onClick={() => nav('Movies')}>Movies <Movies style = {{width: '60px', height: '60px', padding: '5px'}}/></button></td>
                <td><button onClick={() => nav('Biology') }>  Biology <Biology style = {{width: '60px', height: '60px', padding: '7px'}}/> </button></td>
            </tr>
            <tr>
                <td><button onClick={() => nav('Countries') }> Countries <Countries style = {{width: '60px', height: '60px', padding: '5px'}}/></button></td>
                <td><button onClick={() => nav('Space')}> Space <Space style = {{width: '60px', height: '60px', padding: '5px'}}/></button></td>
                <td><button onClick={() => nav('Cultures')}> Cultures <Cultures style = {{width: '60px', height: '60px', padding: '5px'}}/></button></td>
            </tr>
            </tbody>
            </table>
        </div>
    );
}