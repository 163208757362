import { useState, useRef, useEffect,  useContext } from 'react';
import * as d3 from 'd3';
import { SolvedContext, GuessSubmittedContext, api_url} from './App';

export function D3HomeBackground () {
  //Generates a background of words lined up diagonally across the background  
    const d3container = useRef(null);
    const {guess_Submitted, set_Guess_Submitted} = useContext(GuessSubmittedContext);
    const {solved, _} = useContext(SolvedContext);
    const [coordinates, setCoordinates] = useState({});
    const [wordCoordinate, setWordCoordinate] = useState([]);
    const [wiki_title_2d_list , setWiki_title_2d_list ] = useState([]);
 
    const fonts = ['Merriweather', 'cursive', 'fantasy', 'monospace', 'sans-serif', 'Roboto', 'Open Sans', 'Lato', 'Montserrat'];
   

 
 
 
    const positionWords = () => {
      const svg = d3.select(d3container.current)
      .attr('width', window.screen.availWidth)
      .attr('height', window.screen.availHeight);
         
      const svgWidth = window.screen.availWidth;
      const svgHeight = window.screen.availHeight;
   
      const numberOfDiagonals = 3;
      const wordsArray = Object.keys(coordinates);
      const wordsPerDiagonal = Math.ceil(wordsArray.length / numberOfDiagonals);
   
      const angle = Math.atan2(svgHeight, svgWidth) * (180 / Math.PI);
   
      let data = [];
   
      for (let i = 0; i < numberOfDiagonals; i++) {
        for (let j = 0; j < wordsPerDiagonal; j++) {
          const wordIndex = i * wordsPerDiagonal + j;
          if (wordIndex >= wordsArray.length) break;
   
          const startX = svgWidth - ((svgWidth / numberOfDiagonals) * i);
          const startY = 0;
   
          const stepSize = Math.min(svgWidth, svgHeight) / wordsPerDiagonal;
          const x = startX - stepSize * j;
          const y = startY + stepSize * j;
   
          if (x >= 0 && y < svgHeight) {
            data.push({
              text: wordsArray[wordIndex],
              x: x,
              y: y,
              fontSize: 20,
              opacity: 0.2,
              rotation: -angle,
              fontFamily: fonts[Math.floor(Math.random() * fonts.length)]
            });
          }
        }
      }
     
        svg.selectAll('.word')
          .data(data)
          .join('text')
          .attr('class', 'word')
          .attr('x', d => d.x)
          .attr('y', d => d.y)
          .text(d => d.text)
          .style('font-weight', 'bold')
          .attr('font-size', d => `${d.fontSize}px`)
          .style('fill', 'black')
          .style('font-family', d => d.z)
          .attr('class', 'word non-selectable-text')
          .style('opacity', d => d.opacity);
      };
 
 
 
 
    useEffect(() => {
      const fetchCoordinates = async () => {
          try {
              const response = await fetch(api_url + '/wordmap');
              if (!response.ok) {
                  throw new Error(`Problem: ${response.status}`);
              }
              const data = await response.json();
              setCoordinates(data);
          } catch (error) {
              console.error("API error: " + error);
          }
      };
 
 
      fetchCoordinates();
  }, []);
 
 
 
 
  useEffect(() => {
    const fetchCoordinate = async () => {
        if (guess_Submitted) {
            try {
                const response = await fetch(api_url + '/mapword', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 'word': guess_Submitted })
                });
 
 
                if (!response.ok) {
                    throw new Error(`Problem: ${response.status}`);
                }
 
 
                const data = await response.json();
                setWordCoordinate(data[0]);
                setWiki_title_2d_list(data[1]);
            } catch (error) {
                console.error("API error: " + error);
            }
        }
    };
 
 
    fetchCoordinate();
  }, [guess_Submitted]);
 
    useEffect(() => {
      console.log(wordCoordinate);
      console.log(guess_Submitted);
      positionWords();
    }, [coordinates]);

    return (
      <svg ref={d3container} style={{ width: '100%', height: '100%'}} />
    );
  };