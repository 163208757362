import { useContext, useState } from "react";
import { socket } from "./App";
import { useEffect } from "react";
import { LoadingContext, HintsContext, SolvedContext, GuessContext } from "./App";
import { useSearchParams } from "react-router-dom";
import useScreenSize from "./useScreenSize";

const CLOCK_TIME = 60;

export default function Leaderboard({all_players, player_name, room_name, role}){
    const [ searchParams, setSearchParams ] = useSearchParams();
    const {loading, setLoading} = useContext(LoadingContext);
    const {hints, set_hints} = useContext(HintsContext);
    const {solved, set_solved} = useContext(SolvedContext);
    const {guesses, set_guesses} = useContext(GuessContext) ;
    const [best_score, set_best_score] = useState(0);
    const [ready, set_ready] = useState(false);
    const [completed, set_completed] = useState(false);
    const [clock, set_clock] = useState(CLOCK_TIME);
    const [circleDasharray, setCircleDasharray] = useState('283');
    const screenSize = useScreenSize();

    useEffect(() => {
        if(guesses.count > 0){
            const final_score = guesses.attempts.reduce((max, current) => (current.score > max.score ? current : max), guesses.attempts[0]).score * 100;
            set_best_score(Math.round(final_score)); // can't be done in the time interval for some reason so with each guess the best is calculated here instead.
        }
    }, [guesses])

    useEffect(() => {
        if(completed){
            set_solved(true);
            
            setTimeout(() => { // delay shows the hints with the title and the title for 2 seconds before next game is fetched
                socket.emit('final_guess', room_name, player_name, best_score);
                set_guesses({attempts: [], count: 0});
                set_best_score(0);
            }, 2000);
        }
    }, [completed])

    useEffect(() => {
        setLoading(true); // loading is set to true once component is loaded (ie joining or creating the room )
    }, [])

    const handle_hints = (response) => {

        if (response === "Completed"){ // if completed (ie all rounds have been played)
            set_completed(false);
            setLoading(true);
            set_ready(false);
            set_solved(false);
        } 
        else{
            set_hints({
                hidden: response[0],
                all: response[1],
            });
              
            setSearchParams({ 'a': response[2] }); // the encrypted hints are set as search parameters
            set_solved(false);
            setLoading(false);
            set_completed(false);
            let timer = clock;
    
            const countDown = setInterval(() => { // count down timer is started
                if(timer >= 1){
                    timer -= 1;
                    set_clock(timer);

                    setCircleDasharray(`${(
                        timer/CLOCK_TIME * 283
                    ).toFixed(0)} 283`);// the count down svg is updated to show the remaining time (maybe change to d3)
                }
                else{
                    set_clock(CLOCK_TIME);
                    setCircleDasharray('283');
                    set_solved(true);
                    set_completed(true);
                    clearInterval(countDown);// exit the count down interval.
                }
            }, 1000);
        }


    };

    useEffect(() => {
        if(ready){
            socket.on('hints', handle_hints);
            setLoading(true);
        }
        // else{
        //     setLoading('leaderboard_only')
        // }
    }, [ready]); // useEffect allows for replaying the game easily

    function handle_ready(e){
        socket.emit('ready', room_name);// updates the users 'ready' state in the socket room.
        set_ready(true);
        set_guesses({attempts: [], count: 0});
    }

    function removePlayer(e){
        const name = e.target.id;
        socket.emit('remove_player', name, room_name); 
        // sends the username and room name to the socket to remove user (only available for room creator)
    }

    function leaveGame(e){
        socket.emit('leave_game', player_name, room_name); //sends the username and room name to the socket to remove themselves
    }

    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight: '100px', width: 'fit-content', gap: '15px'}}>
            {
                ready ?  
                <div className="base-timer">
                    <svg className="base-timer-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <g className="base-timer-circle">
                        <circle className="base-timer-path-elapsed" cx="50" cy="50" r="45" />
                        <path
                        id="base-timer-path-remaining"
                        strokeDasharray={circleDasharray}
                        stroke="#0D6EFD"
                        className="base-timer-path-remaining"
                        d="
                        M 50, 50
                        m -45, 0
                        a 45,45 0 1,0 90,0
                        a 45,45 0 1,0 -90,0
                        "
                    ></path>
                        </g>
                    </svg>
                    <span className="base-timer-label">
                        {clock}
                    </span>
                </div>
                :  
               <></>
            }
            <div className="d-flex flex-column justify-content-center align-items-center p-2 shadow-lg rounded" style={{width: `${screenSize.width > 680? '30dvw' : '90dvw'}`, backgroundColor: 'white', minWidth: 'fit-content'}}>
                <h2 className="fs-6"> Leaderboard </h2>
                <h2 className="fs-6"> {room_name} </h2>

                <div className="container">
                    {
                        all_players.sort((a, b) => b[1] - a[1]).map((player, ind) => (
                            <div key={20 + ind} className="row">
                                
                                <div className= {`col d-flex justify-content-end align-items-center ${player_name === player[0] ? 'text-success fs-6' : '' }`} >
                                {
                                    role === "create" && player_name !== player[0] ? 
                                        <button id = {player[0]} className="btn text-danger" onClick={removePlayer}> - </button> 
                                    : 
                                        player_name === player[0] ? 
                                            <button id = {player[0]} className="btn text-danger" onClick={leaveGame}> <i className="bi bi-x"></i> </button> 
                                        :

                                        <></>

                                }
                                    {player[0]}
                                </div>
                                <div className="col d-flex justify-content-start align-items-center px-1" >
                                    {player[1]}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            {
                ready ?  
                <></>
                :  
                <button className="btn bg-off-white" onClick={handle_ready} hidden={ready}>
                    Ready
                </button>
            }
        </div>
    );
}