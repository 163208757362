import { useState, useContext, useEffect, React } from 'react';
import { GuessContext, SolvedContext, GuessSubmittedContext, LoggedUserContext, LoadingContext } from './App';
import { api_url } from './App';
import { useSearchParams } from 'react-router-dom';

export function UserInput({page}){
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ title, setTitle ] = useState();
    const [ wait, setWait ] = useState(true);
    const {solved, set_solved} = useContext(SolvedContext);
    const [input_value, set_input_value] = useState('');
    const [invalid_input, set_invalid_input] = useState(false);
    const {guesses, set_guesses} = useContext(GuessContext);
    const {loggedUser, setLoggedUser} = useContext(LoggedUserContext);
    const {guessSubmitted, set_Guess_Submitted} = useContext(GuessSubmittedContext);
    const { loading, setLoading } = useContext(LoadingContext);

    const handleInputChange = (e) => {
        set_input_value(e.target.value);
    };

    const update_guess = (e) => {
        e.preventDefault();
        
        if (input_value){
            const title = searchParams.get('a');

            score(input_value, title)
            .then(similarity => {
                if (similarity === "Invalid"){
                    alert("Server error scoring your guess. Please try again.");
                }
                else if  (similarity === "False"){
                    set_invalid_input(true);

                    setTimeout(() => {
                        set_invalid_input(false);
                      }, 600);
                }
                else {
                    set_invalid_input(false);
                    let solved_storage = false
                    
                    const similarity_score = Number(similarity);
                    if (similarity_score > 0.96){
                        set_solved(true);
                        solved_storage = true

                        const user_data = JSON.parse(localStorage.getItem("user"));
                        
                        if (page === "infinite"){
                            let longest_streak = user_data.longest_streak_unlimited;

                            if (longest_streak < user_data.streak_unlimited + 1){
                                longest_streak = user_data.streak_unlimited + 1
                            }
                        
                            localStorage.setItem('user', JSON.stringify({
                                ...user_data,
                                completed_unlimited: user_data.completed_unlimited + 1,
                                streak_unlimited: user_data.streak_unlimited + 1,
                                longest_streak_unlimited: longest_streak
                            }));

                            setLoggedUser((prev) => ({
                                ...prev,
                                completed_unlimited: prev.completed_unlimited + 1,
                                streak_unlimited: prev.streak_unlimited + 1,
                                longest_streak_unlimited: longest_streak
                            }));
                        }
                        else if (page === 'daily'){
                            let longest_streak = user_data.longest_streak_daily;

                            if (longest_streak < user_data.streak_daily + 1){
                                longest_streak = user_data.streak_daily + 1
                            }

                            localStorage.setItem('user', JSON.stringify({
                                ...user_data,
                                completed_daily: user_data.completed_daily + 1,
                                streak_daily: user_data.streak_daily + 1,
                                longest_streak_daily: longest_streak
                            }));

                            setLoggedUser((prev) => ({
                                ...prev,
                                completed_daily: prev.completed_daily + 1,
                                streak_daily: prev.streak_daily + 1,
                                longest_streak_daily: longest_streak
                            }));
                        }

                        if (loggedUser.name && page !== 'multiplayer'){
                            fetch(api_url+"/update_scores", {
                                method: "POST",
                                headers: {
                                'Content-Type' : 'application/json'
                                },
                                body: JSON.stringify({
                                    username: loggedUser.name,
                                    correct: 'correct',
                                    article: title,
                                    game_type: page
                                })
                            })
                            .then(response => response.json())
                            .then(data => {
                                if (data === "Invalid"){
                                    alert("Server error updating your score in the database.");
                                }
                            });
                        } 
                        fetch(api_url+"/decrypt_title", {
                            method: "POST",
                            headers: {
                            'Content-Type' : 'application/json'
                            },
                            body: JSON.stringify(searchParams.get('a'))
                        })
                        .then(response => response.json())
                        .then(data => {
                            if (data === "Invalid!"){
                                alert("Server error fetching the wiki title");
                            }
                            else{
                                setTitle(data);
                            }
                        });                       

                    } 
                    else if (guesses.count === 5){

                        if (loggedUser.name && page !== 'multiplayer'){
                            fetch(api_url+"/update_scores", {
                                method: "POST",
                                headers: {
                                'Content-Type' : 'application/json'
                                },
                                body: JSON.stringify({
                                    username: loggedUser.name,
                                    correct: "incorrect",
                                    article: title,
                                    game_type: page
                                })
                            })
                            .then(response => response.json())
                            .then(data => {
                                if (data === "Invalid"){
                                    alert("Server error updating your score in the database.");
                                }
                            })
                        }          
                        const user_data = JSON.parse(localStorage.getItem("user"));
                        solved_storage = true;
                        set_solved(true);

                        if (page === "infinite"){
                            localStorage.setItem('user', JSON.stringify({
                                ...user_data,
                                completed_unlimited: user_data.completed_unlimited + 1,
                                streak_unlimited: 0

                            }));

                            setLoggedUser((prev) => ({
                                ...prev,
                                completed_unlimited: prev.completed_unlimited + 1,
                                streak_unlimited: 0
                            }));
                        }
                        else if (page === "dialy"){

                            localStorage.setItem('user', JSON.stringify({
                                ...user_data,
                                completed_daily: user_data.completed_daily + 1,
                                streak_daily: 0
                            }));

                            setLoggedUser((prev) => ({
                                ...prev,
                                completed_daily: prev.completed_daily + 1,
                                streak_daily: 0
                            }));
                        }

                        fetch(api_url+"/decrypt_title", {
                            method: "POST",
                            headers: {
                            'Content-Type' : 'application/json'
                            },
                            body: JSON.stringify(searchParams.get('a'))
                        })
                        .then(response => response.json())
                        .then(data => {
                            if (data === "Invalid!"){
                                alert("Server error fetching the wiki title");
                            }
                            else{
                                setTitle(data);
                            }
                        });
                        
                    } 

                    const new_guess = {
                        "text": input_value,
                        "score": similarity_score
                    };
    
                    const all_attempts = [new_guess, ...guesses.attempts].sort((a,b) => b.score - a.score);
                    const all_guesses = {
                        attempts: all_attempts,
                        count: guesses.count + 1
                    };
        
                    set_guesses(prev => ({
                        attempts: all_attempts, 
                        count: prev.count + 1 
                    }));
                    
                    if (page !== "multiplayer"){
                        localStorage.setItem("last_guesses_"+page, JSON.stringify(all_guesses));
                        localStorage.setItem("solved_"+page, JSON.stringify(solved_storage));
                    }
                    
                    set_input_value('');       
                }

            })

        }

    };

    useEffect(() => {
        if(page !== "multiplayer"){
            const last_guess = JSON.parse( localStorage.getItem("last_guesses_"+page) ) ;
            const was_solved = JSON.parse( localStorage.getItem('solved_'+page) );
    
            if ( last_guess  ){
                set_guesses(last_guess);
            } 
            else {
                set_guesses({attempts: [], count: 0});
            }
            if ( was_solved ){
                set_solved(was_solved);
    
                if (was_solved === true){
                    setWait(true);
    
                    fetch(api_url+"/decrypt_title", {
                        method: "POST",
                        headers: {
                        'Content-Type' : 'application/json'
                        },
                    body: JSON.stringify(JSON.parse(localStorage.getItem("a_" + page)))
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data === "Invalid!"){
                            alert("Server error fetching the wiki title");
                        }
                        else{
                            setTitle(data);
                        }
                    });
                    setWait(false);
                }
            } 
            else {
                set_solved(false);
            }
        }
    }, []);

    useEffect(()=>{
        if (page === 'multiplayer' && searchParams.get('a')){
            fetch(api_url+"/decrypt_title", {
                method: "POST",
                headers: {
                'Content-Type' : 'application/json'
                },
                body: JSON.stringify(searchParams.get('a'))
            })
            .then(response => response.json())
            .then(data => {
                if (data === "Invalid!"){
                    alert("Server error fetching the wiki title");
                }
                else{
                    setTitle(data);
                }
            });
        }
    }, [searchParams.get('a')]);

    const score = async (guess, title) => {
        set_Guess_Submitted(guess);
        return fetch(api_url+"/similarity", 
        {
            method: "POST",
            headers: {
              'Content-Type' : 'application/json'
              },
            body: JSON.stringify({
                guess: guess,
                title: title
            })
        })
        .then(response => {
            return  response.json();
        })
        .then(data => {
            return data;
        })
    };

    return (
        <div className="d-flex flex-column justify-content-center align-items-center pb-3">

            <div className="d-flex justify-content-center align-items-center py-4">
                {
                    solved || guesses.count >= 6 ?
                    
                    <div>
                        <a href= {`${"https://en.wikipedia.org/wiki/" + title}`} target = '_blank' rel="noreferrer" > { title } </a>
                    </div>
                    :
                    <form onSubmit={update_guess}>
                        <input 
                            type="text" id = "user_input" name = "user_guess" placeholder="GuessTheWiki"             
                            value={input_value}
                            onChange={handleInputChange}
                            disabled = {guesses.count >= 6 || solved || loading}
                            className= {`text-center ${ invalid_input ? 'shake': '' }`}>
                        </input>
                    </form>
                }
                
            </div>

            <div className='container'>
                {
                    guesses.count > 0 ? 
                        guesses.attempts.map((guess, ind) => (
                        <div className="row" key={ind + 10}>
                            <div className={`col d-flex justify-content-end align-items-center ${guess.score > 0.95 ? 'text-success fs-4'  : 'fs-5'}`} key={ind+100}>
                                { guess.text }
                            </div>
                            <div className={`col d-flex justify-content-start align-items-center ${guess.score > 0.95 ? 'text-success fs-4'  : 'fs-5'}`} key={ind+200}>
                                <div className="progress" style={{ width: '100px' }}>
                                    <div 
                                        className={`progress-bar ${guess.score > 0.95 ? 'bg-success' : ''}`} 
                                        role="progressbar" 
                                        style={{ width: `${Math.round(guess.score * 100)}%` }} 
                                        aria-valuenow={Math.round(guess.score * 100)} 
                                        aria-valuemin="0" 
                                        aria-valuemax="100"
                                    >
                                        {Math.round(guess.score * 100)}%
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))
                    :
                        ""
                }
            </div>
    
        </div>

    );
}